

// Time ranges in milliseconds
export const HOUR_RANGE = 1000 * 60 * 60;
export const DAY_RANGE  = 1000 * 60 * 60 * 24;
export const WEEK_RANGE = 1000 * 60 * 60 * 24 * 7; 
export const YEAR_RANGE = 1000 * 60 * 60 * 24 * 365;

export const MAX_HOURLY_DATA_RANGE = WEEK_RANGE * 2;

// Labels
export const ANOMALY_LABEL = 'Anomalies';
export const INCIDENT_LABEL = 'Incidents';
export const DISTINCTMMSI_LABEL = 'Total Vessels with AIS';
export const VESSELS_WITH_ANOMALIES_LABEL = 'Vessels with Anomalies';

export const UPPER_CHART_TITLE = "Anomalies and Incidents per ";
export const LOWER_CHART_TITLE = "Vessels with Anomalies per ";