<template>
    <div class="label-container">
    <div id="label"> 
      Showing: <b>{{ formatDate(start) }} - {{ formatDate(end) }} (UTC)</b><br/>
      Mode: <b class="tooltip" data-tooltip="Data in a time range up to 2 weeks is hourly; beyond that, it's daily">{{mode}}</b>
    </div>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "GraphStatus",
    props: {
      start: Number,
      end: Number,
      mode:String,
    },
    data() {
      return {
        optionsDate: { year: 'numeric', month: 'short', day: '2-digit' },
        optionsTime: {hour: '2-digit', minute: '2-digit'}
      }
    },
    methods: {
      formatDate(date) {

        const utcTime = new Intl.DateTimeFormat('en-GB', {
          hour: '2-digit',
          minute: '2-digit',
          year: 'numeric',
          month: 'short',
          day: '2-digit',
          timeZone: 'UTC'
        }).format(new Date(date))

        return `${utcTime}` || ''
      }
    }
  }
  </script>
  
  <style scoped>
  .label-container {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 0;
  }
  
  .label-container > * {
    font-size: 11px;
    color: #666666;
    line-height: 18px;
    display: block;
    margin: 0;
    padding: 5px 10px;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  }

  .tooltip {
    position: relative;
    cursor: pointer;
  }

  .tooltip::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 150%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0,0,0,0.6);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 12px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s;
    font-weight: normal;
  }

  .tooltip:hover::after {
    opacity: 1;
    visibility: visible;
  }


  </style>