export const CrosshairPlugin = {
    id: 'CrosshairPlugin',

    beforeDatasetsDraw(chart) {
        const { ctx, chartArea: { top, bottom, left, right } } = chart;
        const crosshair = chart.$crosshair;

        if (crosshair && crosshair.visible && crosshair.x >= left && crosshair.x <= right) {
            ctx.save();
            ctx.beginPath();
            ctx.strokeStyle = 'rgba(0,0,0,0.7)';
            ctx.lineWidth = 1;
            ctx.moveTo(crosshair.x, top);
            ctx.lineTo(crosshair.x, bottom);
            ctx.stroke();
            ctx.restore();
        }
    },
    afterEvent(chart, args) {
        const event = args.event;
        if(event.native.isTrusted){
            let syncData = {chartId: chart.id, type: event.type};
            if(!args.inChartArea) syncData.type = 'mouseout';

            if(syncData.type === 'mouseout'){
                chart.$crosshair = {
                    x: null,
                    visible: false,
                };
                chart.canvas.dispatchEvent(new MouseEvent('mouseout'));
            }
            else if (syncData.type === 'mousemove' || syncData.type === 'click') {
                const { chartArea: {  left, right, top, bottom } } = chart;
                const canvasX = event.x;
                const canvasY = event.y;
        
                if (canvasX >= left && canvasX <= right && canvasY >= top && canvasY <= bottom) {
                    chart.$crosshair = {
                        x: canvasX,
                        visible: canvasX >= left && canvasX <= right,
                    };
                    syncData = {...syncData, canvasX, clientX: event.native.clientX , canvasY};
                    chart.draw();
                }
            }
            window.dispatchEvent(new CustomEvent('sync-event', { detail: syncData }));
        }
    }
};

export const TooltipPlugin = {
    id: 'tooltipPlugin',

    afterDraw(chart) {
        const {tooltip, ctx} = chart;
        if (!tooltip || !tooltip.dataPoints || tooltip.opacity === 0) return;
        const title = tooltip.dataPoints[0]?.tooltipTitle;
        if (!title) return;

        const textWidth = ctx.measureText(title).width;
        const padding = 5;
        const width = textWidth + padding * 2;
        const height = 25;
        const x = tooltip.caretX - width / 2;
        const y = tooltip._eventPosition.y - height - 10;
        const radius = 6;

        ctx.font = 'bold 12px Arial';
        ctx.save();
        
        ctx.fillStyle = tooltip.options.backgroundColor;
        ctx.beginPath();
        ctx.moveTo(x + radius, y);
        ctx.lineTo(x + width - radius, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
        ctx.lineTo(x + width, y + height - radius);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height);
        ctx.lineTo(x + radius, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
        ctx.lineTo(x, y + radius);
        ctx.quadraticCurveTo(x, y, x + radius, y);
        ctx.closePath();
        ctx.fill();

        const arrowWidth = 10, arrowHeight = 6;
        ctx.beginPath();
        ctx.moveTo((x+width/2) - arrowWidth / 2, y + height); 
        ctx.lineTo((x+width/2), y + height + arrowHeight);
        ctx.lineTo((x+width/2) + arrowWidth / 2, y + height);
        ctx.closePath();
        ctx.fill();
    
    
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.fillText(title, tooltip.caretX, y + height / 2 + 4);
    
        ctx.restore();
      }
};