<template>
  <div>
    <v-list>
      <div style="padding-left: 16px">
        <v-list-item-title>Last Anomalies</v-list-item-title>
      </div>
      <v-list-item
          v-for="anomaly in anomalies"
          :key="anomaly.id"
          :title="anomaly.vesselName || anomaly.mmsi"
          :subtitle="anomaly.startTime">
        <template v-slot:append>
          <v-btn
              icon="mdi-magnify"
              variant="text"
              v-on:click="showAnomaly(anomaly)"
          ></v-btn>
        </template>
      </v-list-item>
    </v-list>

  </div>
</template>

<script>
import authorizedFetch from "@/services/AuthorizedFetch";

export default {
  name: "LastAnomalies",
  data() {
    return {
      anomalies: []
    };
  },
  methods: {
    initEventBus(reconnectAttempts = 0) {
      console.log("Initializing event bus...");

      authorizedFetch("api/anomaly/events")
          .then((response) => {
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const reader = response.body.getReader();
            const decoder = new TextDecoder("utf-8");

            let partialData = "";
            const processStream = ({ done, value }) => {
              if (done) {
                console.log("Stream closed by the server.");
                this.scheduleReconnection(reconnectAttempts);
                return;
              }
              const chunk = decoder.decode(value);
              partialData += chunk;
              let lines = partialData.split("\n");
              partialData = "";

              lines.forEach((line) => {
                if (line.startsWith("data:")) {
                  line = line.substring(5).trim();
                }

                if (line.trim() === "") {
                  return;
                }

                try {
                  const parsedData = JSON.parse(line);
                  console.log("Parsed event data:", parsedData);
                  this.anomalies = parsedData;
                } catch (error) {
                  // If parsing fails, accumulate partial data for the next chunk
                  console.warn("Incomplete data, waiting for more chunks...");
                  partialData += line + "\n";
                }
              });

              return reader.read().then(processStream).catch((error) => {
                console.error("Stream processing error:", error);
                this.scheduleReconnection(reconnectAttempts);
              });
            };
            reader.read().then(processStream).catch((error) => {
              console.error("Error processing stream:", error);
              this.scheduleReconnection(reconnectAttempts);
            });
          })
          .catch((error) => {
            console.error("Error fetching event stream:", error);
          });
    },
    scheduleReconnection(reconnectAttempts) {
      const maxAttempts = 10;
      const delay = Math.min(1000 * 2 ** reconnectAttempts, 30000);

      if (reconnectAttempts >= maxAttempts) {
        console.error("Max reconnection attempts reached. Giving up.");
        return;
      }

      console.log(`Reconnecting in ${delay / 1000} seconds... (Attempt ${reconnectAttempts + 1})`);
      setTimeout(() => {
        this.initEventBus(reconnectAttempts + 1); // Retry with incremented attempts
      }, delay);
    },
    showAnomaly(anomaly) {
      this.$emit('showAnomaly', anomaly);
    }
  },
  created() {
    console.log('Last anomalies created..')
    this.initEventBus();
  }
};
</script>

<style scoped>
h1 {
  font-size: 1.5em;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  margin: 0.5em 0;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>
