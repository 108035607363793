<template>
    <v-navigation-drawer class="text-light-blue">
    <v-divider></v-divider>
    <br/>
    <v-list-item>
      <v-list-item>
        <v-list-item-title >Graph Filter</v-list-item-title>
      </v-list-item>
    </v-list-item>
    <div class="map-overlay top">
      <div class="map-overlay-inner">
        <fieldset>
            <nav id="filter-group" class="filter-group">
              <div v-for="(data,index) in dataset?.top?.datasets" @click="check(index,1)" :key="index">
                <input type="checkbox" name="data.label" :checked="!data.hidden" >
                <label>{{ data.label }}</label>
              </div>
              <div v-for="(data,index) in dataset?.bottom?.datasets" @click="check(index,2)" :key="index">
                <input type="checkbox" name="data.label" :checked="!data.hidden" >
                <label>{{ data.label }}</label>
              </div>
            </nav>
        </fieldset>
      </div>
    </div>

    </v-navigation-drawer>

</template>

<script>
export default{
    name: 'GraphFilters',
    props:["dataset"],
    methods:{
        check(index,chart){
            this.$emit('labelSelect', {index,chart});
        }
    }
}

</script>


<style scoped>

.map-overlay {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  width: 100%;
}

.map-overlay .map-overlay-inner {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.map-overlay-inner fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay-inner fieldset:last-child {
  margin: 0;
}

.map-overlay-inner select {
  width: 100%;
}

.map-overlay-inner label {
  display: block;
  font-weight: bold;
}

.map-overlay-inner button {
  background-color: #3386c0;
  color: white;
  display: inline-block;
  height: 20px;
  border: none;
  cursor: pointer;
}

.map-overlay-inner button:focus {
  outline: none;
}

.map-overlay-inner button:hover {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.1);
}






.filter-group {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  z-index: 1;
  border-radius: 3px;
  color: #2196F3;
  padding: 0;
}

.filter-group input[type='checkbox']:first-child + label {
  border-radius: 3px 3px 0 0;
}

.filter-group label:last-child {
  border-radius: 0 0 3px 3px;
  border: none;
}

.filter-group input[type='checkbox'] {
  display: none;
}

.filter-group input[type='checkbox'] + label {
  background-color: #fff;
  display: block;
  cursor: pointer;
  padding: 16px;
  text-transform: capitalize;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

.filter-group input[type='checkbox'] + label:hover,
.filter-group input[type='checkbox']:checked + label {
  background-color: #ADD8E6;
}

.filter-group input[type='checkbox']:checked + label:before {
  content: '✔';
  margin-right: 5px;
}

</style>